<!-- 消息管理 -->
<template>
  <div id="message">
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>告警管理</span>
      </div>
      <div class="content">
        <div class="query-form">
          <el-form class="form" ref="form" :inline="true" :model="requestParam">
            <!-- <el-form-item label="接收人">
              <el-input placeholder="请输入接受人" v-model.trim="requestParam.recipientName" clearable></el-input>
            </el-form-item> -->
            <el-form-item label="场站名称">
            <el-select filterable  v-model="requestParam.stationNumber" :popper-append-to-body="false" clearable placeholder="请选择场站名称">
              <el-option v-for="item in stationData" :key="item.stationNumber" :label="item.stationName +' '+ item.stationNumber"
                :value="item.stationNumber"></el-option>
            </el-select>
          </el-form-item>
            <el-form-item label="消息状态">
              <el-select :popper-append-to-body="false" v-model="requestParam.messageFlag" placeholder="请选择消息状态"
                clearable>
                <el-option label="已读" value="1"></el-option>
                <el-option label="未读" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="告警类型">
              <el-select :popper-append-to-body="false" v-model="requestParam.alarmType" placeholder="请选择告警类型"
                clearable>
                <el-option label="提示" :value="1"></el-option>
                <el-option label="预警" :value="2"></el-option>
                <el-option label="报警" :value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="告警级别">
              <el-select :popper-append-to-body="false" v-model="requestParam.levelId" placeholder="请选择告警级别" clearable>
                <el-option label="一般告警" value="1"></el-option>
                <el-option label="严重告警" value="2"></el-option>
                <el-option label="重大告警" value="3"></el-option>
                <el-option label="特别重大告警" value="4"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="消警状态">
              <el-select :popper-append-to-body="false" v-model="requestParam.eliminateAlarmFlag" placeholder="请选择消警状态"
                clearable>
                <el-option label="是" :value="1"></el-option>
                <el-option label="否" :value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="开始时间">
              <el-date-picker type="date" placeholder="请选择开始时间" value-format="yyyy-MM-dd"
                v-model="requestParam.startTime" :append-to-body="false" @change="checkDate"></el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间">
              <el-date-picker type="date" placeholder="请选择结束时间" value-format="yyyy-MM-dd"
                v-model="requestParam.endTime" :append-to-body="false" :picker-options="pickerOptions"
                @change="checkDate"></el-date-picker>
            </el-form-item>
            <!-- <el-form-item label="开始时间">
              <el-date-picker placeholder="请选择开始时间" type="date" value-format="yyyy-MM-dd" clearable
                v-model="requestParam.createdAtStart" :append-to-body="false" @change="checkDate"></el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间">
              <el-date-picker placeholder="请选择结束时间" type="date" value-format="yyyy-MM-dd"
                v-model="requestParam.createdAtEnd" :picker-options="pickerOptions" :append-to-body="false"
                @change="checkDate"></el-date-picker>
            </el-form-item> -->
          </el-form>
          <div class="query-button">
            <el-button type="primary" icon="el-icon-search" @click="formQuery">查询</el-button>
            <el-button type="primary" @click="allDelete()">批量消警</el-button>
          </div>
        </div>

        <el-table class="table" :data="tableData" v-loading="loading" element-loading-text="加载中"
          element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.3)"
                  @selection-change="handleSelectionChange">
          <div slot="empty">
            <div v-if="tableData.length == 0">
              <div class="empty-data-view">
                <div class="table-text">
                  {{ loading ? "" : "暂无数据" }}
                </div>
              </div>
            </div>
          </div>
          <el-table-column type="selection" width="50" :selectable="chechSelectable"></el-table-column>
          <el-table-column align="center" type="index" label="序号" width="75">
            <template slot-scope="scope">
              <span>{{
                (requestParam.pageNum - 1) * requestParam.pageSize +
                scope.$index +
                1
              }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="levelName" label="告警级别">
          </el-table-column>
          <el-table-column align="center" label="告警类型">
            <template slot-scope="scope">
              <span v-if="scope.row.levelId == 3 || scope.row.levelId == 4">报警</span>
              <span v-else-if="scope.row.levelId == 2">预警</span>
              <span v-else="scope.row.levelId == 1">提示</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="title" label="标题">
          </el-table-column>
          <el-table-column align="center" prop="content" label="发送文本" width="300">
          </el-table-column>
          <!-- <el-table-column align="center" prop="latitude" label="链接">
          </el-table-column> -->
          <!-- <el-table-column align="center" prop="recipientName" label="接收人">
          </el-table-column> -->
          <el-table-column align="center" prop="messageFlag" label="消息状态">
            <template slot-scope="scope">
              <span>{{ scope.row.messageFlag === 1 ? "已读" : "未读" }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="createTime" label="告警时间" width="160">
          </el-table-column>
          <el-table-column align="center"  label="消警状态">
              <template slot-scope="scope">
                <div>
                  <img style="vertical-align: middle;" v-if="scope.row.eliminateAlarmFlag == 1" src="../../assets/images/alarm/xiaojing.png">
                  <img style="vertical-align: middle;" v-if="scope.row.eliminateAlarmFlag == 0" src="../../assets/images/alarm/baojing.png">
                </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" prop="operate" fixed="right" width="200">
            <template slot-scope="scope">
              <div class="operate">
                <el-button type="text" @click="updateDialog(scope.row)">详情</el-button>
                <el-button type="text" @click="xiaojing(scope.row)">消警</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <button class="home-page" @click="homePage">首页</button>
          <el-pagination :current-page="requestParam.pageNum" :page-sizes="[10, 20, 30, 40]"
            layout="prev, pager, next, sizes,slot" prev-text="上一页" next-text="下一页" :total="total" background
            @size-change="changeSize" @current-change="changeCurrent">
          </el-pagination>
          <button class="tail-page" @click="tailPage">尾页</button>
          <div class="jump-pagination">
            <span class="text">跳至</span>
            <el-input v-model="inputValue" size="mini" class="pagination-input" @input="checkPageNum"
              @change="inputValueChange"></el-input>
            <span class="text">页</span>
            <button class="confirm" @click="confirm">确定</button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="详情" :visible.sync="dialogVisible" width="751px" @close="dialogClose">
      <div class="dialog-content">
        <el-form class="dialog-form" :inline="true" :model="detailsData">

          <el-form-item label="标题">
            <el-input v-model="detailsData.title" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="告警级别">
            <el-input v-model="detailsData.levelName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="告警类型">
            <el-input v-model="detailsData.typeName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="告警时间">
            <el-input v-model="detailsData.createTime" :readonly="true"></el-input>
          </el-form-item>

          <!-- <el-form-item label="接收人">
            <el-input v-model="detailsData.recipientName" :readonly="true"></el-input>
          </el-form-item> -->
          <el-form-item label="消息状态">
            <el-input v-model="detailsData.readUnread" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="发送文本">
            <el-input v-model="detailsData.content" :readonly="true" type="textarea" :rows="3"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer"></div>
    </el-dialog>
    <!-- 删除 -->
    <el-dialog title="消警" :visible.sync="xjDialogVisible" width="20%">
      <div class="deleteCenter">
        确定对此条告警消警?
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <div class="">取消</div>
          <div class="">确定</div> -->
        <el-button class="dialogBtn" @click="xjDialogVisible = false">取 消</el-button>
        <el-button class="dialogBtn" type="primary" @click="xjSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="批量消警" :visible.sync="plxjDialogVisible" width="20%">
      <div class="deleteCenter">
        是否进行批量消警?
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="dialogBtn" @click="plxjDialogVisible = false">取 消</el-button>
        <el-button class="dialogBtn" type="primary" @click="plxjSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
// import { messageApi } from "@/api/message.js";
import { alarmApi } from "@/api/alarm.js";
import { inspectionFormApi } from "@/api/inspectionForm.js";
import eventBus from "../../api/NoticeEventBus.js";
import eventBus1 from '../../api/NoticeEventBus.js'
// import eventBus from '../../api/NoticeEventBus'
export default {
  name: "",

  data() {
    return {
      tableSelectData: [],
      tableData: [],
      stationData: [],
      requestParam: {
        pageNum: 1,
        pageSize: 10,
        levelId: this.$route.query.alarmId || "",
        messageFlag:'',
        messageType:'',
        recipientName:'',
        stationNumber:'',
        startTime:'',
        endTime:'',
        alarmType:'',
        // createdAtEnd: null,
        // createdAtStart: null,
      },
      total: 0,
      inputValue: "1",
      id: "",
      loading: false,
      dialogVisible: false,
      xjDialogVisible:false,
      plxjDialogVisible:false,
      detailsData: {},
      content:'',
      eliminateAlarmFlag:'',
      stationNumber:'',
      time:''
    };
  },

  created() {
    this.$store.commit("increment", "Eios控制面板");
    this.$store.commit("selectChild", "告警管理");
    this.$store.commit("selectChildren", "");
    // this.requestParam.stationNumber = sessionStorage.getItem("stationNumber");
  },
  mounted() {
    if(this.$route.query.type == '1'){
      this.dialogVisible = false;
    }else if(this.$route.query.type == '2'){
      this.dialogVisible = true;
    }
    let selectedAlarm = this.$route.params;
// console.log('`````````````',this.$route.query);
    if (Object.keys(selectedAlarm).length !== 0) {
      this.detailsData = selectedAlarm;
      this.id = selectedAlarm.messageId;
      this.dialogVisible = true;
    }

    if(this.id){
      this.requestParam.stationNumber=''
        this.queryMessage();
    }else{
      if(this.$route.query=={}){
        this.requestParam.stationNumber=''
        this.queryMessage();
      }else{
        this.queryMessage(); //获取消息
      }

    }
    this.queryStation()
    eventBus.$on("updateDialog", (data) => {
      this.updateDialog(data)
    });
  },
  methods: {
    allDelete(){
      if(this.tableSelectData.length == 0){
        this.$message('请至少选择一条数据')
        return
      }
      this.plxiaojing()
    },
    handleSelectionChange(val) {
      this.tableSelectData = val;
    },
    chechSelectable(row){
      return row.levelId != 3 && row.levelId != 4
    },
    queryStation() {
      inspectionFormApi.queryStationName().then((res) => {
        if (res.code === 200) {
          this.stationData = res.data;
        }
      });
    },
    //消警确认
    xjSubmit(e){
      let params = {
        content:this.content,
        eliminateAlarmFlag:this.eliminateAlarmFlag == '1'?0:1,
        stationNumber:this.stationNumber,
        time:this.time
      }
      alarmApi.updAlaMesEliminateAlarmFlag(params).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: 'success',
            duration: 3000,
            customClass: "messageText",
          });
          this.xjDialogVisible = false
          this.queryMessage(); //获取消息
          eventBus.$emit('queryMessage')
        }
      });
    },
    //消警确认
    plxjSubmit(e){
      let ids = []
      this.tableSelectData.forEach(item => {
        console.log(item)
        ids.push(String(item.messageId));
      })
      console.log(ids)
      let params = {
        idlist:ids
      }
      alarmApi.updAlaMesEliminateAlarmFlag(params).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: 'success',
            duration: 3000,
            customClass: "messageText",
          });
          this.plxjDialogVisible = false
          this.queryMessage(); //获取消息
          eventBus.$emit('queryMessage')
        }
      });
    },
    //消警弹窗
    xiaojing(params){
      this.xjDialogVisible = true
      this.content = params.content
      this.eliminateAlarmFlag = params.eliminateAlarmFlag
      this.stationNumber = params.stationNumber
      this.time = params.createTime.split(' ')[0]
      console.log(this.content)
      console.log(this.eliminateAlarmFlag)
      console.log(this.stationNumber)
      console.log(this.time)
    },
    //批量消警弹窗
    plxiaojing(params){
      this.plxjDialogVisible = true
    },
    formQuery() {
      this.requestParam.pageNum = 1;
      this.queryMessage();
    },
    checkPageNum(e) {
      let value = e.replace(/[^\d]/g, ""); // 只能输入数字
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      this.inputValue = value;
    },
    //页数输入
    inputValueChange() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      if (this.inputValue < 1) {
        this.inputValue = 1;
      } else {
        this.inputValue =
          this.inputValue < lastPage ? this.inputValue : lastPage;
      }
    },
    //首页
    homePage() {
      this.requestParam.pageNum = 1;
      this.queryMessage();
    },
    //尾页
    tailPage() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      this.requestParam.pageNum = lastPage;
      this.queryMessage();
    },
    confirm() {
      this.requestParam.pageNum = this.inputValue;
      this.queryMessage();
      this.inputValue = "";
    },
    changeSize(pageSize) {
      this.requestParam.pageSize = pageSize;
      this.queryMessage();
    },
    changeCurrent(pageNum) {
      this.requestParam.pageNum = pageNum;
      this.queryMessage();
    },
    checkDate() {
      if (this.requestParam.createdAtStart && this.requestParam.createdAtEnd) {
        const startTime = new Date(this.requestParam.createdAtStart).getTime();
        const endTime = new Date(this.requestParam.createdAtEnd).getTime();
        if (startTime > endTime) {
          this.requestParam.createdAtEnd = null;
        }
      }
    },
    updateDialog(data) {
      this.id = data.messageId;
      this.detailsData = data;
      // this.detailsData.messageFlag =
      //   this.detailsData.messageFlag === 1 ? "已读" : "未读";
      // this.detailsData.messageScope =
      //   this.detailsData.messageScope === 1 ? "PC" : "移动";
      this.dialogVisible = true;
    },
    dialogClose() {
      if (this.detailsData.messageFlag === 2) {
        this.updateMessageStatus();
      }
    },
    queryMessage() {
      this.loading = true;
      alarmApi.queryAlarmList(this.requestParam).then((res) => {
        this.loading = false;
        if (res.code === 200) {
          if(res.data){
            this.tableData = res.data;
          }else{
            this.tableData = [];
          }
          this.total = res.total;
        }
      });
    },
    updateMessageStatus() {
      console.log("123");
      alarmApi.alarmRead(this.id).then((res) => {
        // this.$message({
        //   message: "修改成功",
        //   type: "success",
        //   duration: 3000,
        //   customClass: "messageText",
        // });
        // this.$store.dispatch("queryMessage");
        this.queryMessage();
        eventBus.$emit('queryMessage')
      });
      eventBus1.$emit('notices-updated-alarm');
    },
  },
  computed: {
    pickerOptions() {
      const _this = this;
      return {
        disabledDate(time) {
          const limitDate = new Date(_this.requestParam.createdAtStart);
          limitDate.setHours(0, 0, 0, 0); // 重置时间为当天的开始时间

          return time.getTime() < limitDate.getTime();
        },
      };
    },
  },
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
#message {
  width: 100%;
  min-height: 781px;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
}
.deleteCenter {
  padding:5% 0 5% 0;
  text-align: center;
  font-size: 16px;
  color: #EBEDF1;
}
.main {
  width: 100%;
  padding: 20px 26px 28px 25px;
  box-sizing: border-box;
}

/* .el-link.el-link--default{
color: #aed6ff;
}
.el-link.is-underline:hover:after{
  border-bottom: 1px solid #aed6ff;
} */
/* 执行结果 */
.dialog-content {
  margin: 20px 41px 0 41px;
}

.dialog-form :deep().el-form-item__label {
  width: 67px;
  padding-right: 0;
  color: #e8f4ff;
  font-size: 12px;
  text-align: right;
  margin-right: 10px;
}

.dialog-form :deep().el-form-item__label::after {
  content: ":";
  margin: 0 3px;
}

.dialog-form :deep() .el-form-item {
  margin: 0 0 16px 0;
}

.dialog-form :deep() .el-input {
  width: 257px;
}

.dialog-form :deep() .el-input .el-input__inner {
  height: 35px;
  width: 100%;
  background-color: #061b45;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
}

.dialog-form :deep() .el-textarea textarea {
  width: 592px;
  background-color: #061b45;
  border: 1px solid #09295b;
  /* border: 0; */
  font-size: 12px;
  color: #aed6ff;
}

.dialog-form :deep() .el-textarea textarea::placeholder {
  font-size: 12px;
  color: #6180a8;
}

.dialog-form :deep() .el-input input::placeholder {
  font-size: 12px;
  color: #6180a8;
}
</style>
